/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "My sister always suffered really badly from anxiety. I never understood this and always dismissed her anxiety as being silly and dramatic. Thankfully, she is doing much better now. She had CBT which was amazing for her, but she of course still gets anxious (Covid hasn’t helped that!), but is nowhere near as bad as she used to be."), "\n", React.createElement(_components.p, null, "I regret thinking about my sister’s anxiety how I used to and dismissing it in the way I did, as I know now how horrendous of a thing that is to do. I never used to suffer with anxiety until I experienced 2 sexual assaults while I was at university, a few months apart. I didn’t realise it at the time but this sparked something in me I didn’t really understand, but now know to be anxiety. I became anxious about things that it didn’t make sense to feel anxious about and would ignore more important things, like whether or not someone would spike my drink (which did happen!)."), "\n", React.createElement("ins", {
    class: "adsbygoogle",
    "data-ad-layout": "in-article",
    "data-ad-format": "fluid",
    "data-ad-client": "ca-pub-1001229702754753",
    "data-ad-slot": "8446829758"
  }), "\n", React.createElement(_components.p, null, "Over a period of time I became aware of how I was feeling and the way I was acting and recognised this wasn’t healthy or good for me. I acknowledged the fact that I had anxiety about things, whether or not I thought they were ‘important’, and that I needed to stop pushing things to the side and ignoring them, hoping that they would go away."), "\n", React.createElement(_components.p, null, "I began a long journey of self-discovery (which I personally don’t think I have finished) where I learnt to accept my anxiety and deal with it. When I have suffered from depressive episodes more recently, my previous experience with anxiety has helped me through them in ways I would not have been capable of 10 years ago."), "\n", React.createElement(_components.p, null, "I am able to understand anxiety now. I am able to recognise when my sister is feeling anxious about something, even if she doesn’t openly disclose what it is. I have been able to help friends through difficult times in their life and I have been able to use what I have learnt in my professional career as well."), "\n", React.createElement(_components.p, null, "I wouldn’t wish what happened to me on anyone and of course if I could take it back I would. However, I think it has made me so aware of mental health and the importance of recognising when you are not okay, and that it is okay not to be okay."), "\n", React.createElement("ins", {
    class: "adsbygoogle",
    "data-ad-layout": "in-article",
    "data-ad-format": "fluid",
    "data-ad-client": "ca-pub-1001229702754753",
    "data-ad-slot": "8446829758"
  }), "\n", React.createElement(_components.p, null, "I really hated myself at one point for how dismissive I used to be of my sister’s anxiety and questioned whether I had missed signs in friends and had not been there for people when they may have needed me, due to my own ignorance. However, I have got over that feeling as that doesn’t do anyone any good. It is more important for me to be able to be there for my sister and friends now than to dwell on the past."), "\n", React.createElement(_components.p, null, "I know my turning point is quite drastic and that it may not resonate with many people, but I hope my overall message does. Mental health has the power to become all-consuming if we allow it. Reach out to people, talk to someone - a family member, a friend, a colleague, a stranger or even a professional. There is no shame in reaching out for help and that is something I wish I had known earlier. Mental health is so important and I am so grateful to Anxious Extroverts for creating such an amazing platform."), "\n", React.createElement(_components.p, null, "Anon "), "\n", React.createElement(_components.p, null, "To find support as a survivor of rape or sexual assault, please click ", React.createElement(_components.a, {
    href: "https://www.mind.org.uk/information-support/guides-to-support-and-services/abuse/sexual-abuse/"
  }, "here"), ". "), "\n", React.createElement(_components.p, null, "For more support, information & advice, please click ", React.createElement(_components.a, {
    href: "https://www.supportline.org.uk/problems/rape-and-sexual-assault/"
  }, "here"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
